$(document).ready(function() {
    if ($(window).width() > 1024) {
    	var initFunction = function() {
    		$('.top__l').pin({
			    containerSelector: '.top .wrapper-fw'
			});
    	}

    	initFunction();
	}
});

$('.form-footer form p').on('click', function(){
	var $this = $(this).closest('form');

	setTimeout(function(){
		if($this.hasClass('sent')) {
			console.log('sent')
			$('.form-footer__done').addClass('is-visible');

			setTimeout(function(){
				$('.form-footer__done').removeClass('is-visible');
				$(this).closest('form').removeClass('sent');
			}, 2000);
		}
	}, 300);
});

$('.header__sign').on('click', function(){
	$('.header__nav').toggleClass('is-more');
	$('.top').toggleClass('socials--animated');
});

$('.top__slider-in').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	prevArrow: $('.top__slider-arrow.prev'),
	nextArrow: $('.top__slider-arrow.next'),
	fade: true
});

if ($(window).width() < 1025) {
	$('.top__l').on('click', function(){
		$('body, html').addClass('is-ovh');

		$('body').addClass('menu--opened');
	});

	$('.menu__search-r .btn-close').on('click', function(){
		$('body').removeClass('menu--opened');

		$('body, html').removeClass('is-ovh');
	});
}

$('.header__socials').on('click', function() {
	$('.top').toggleClass('socials--animated');
});

function goBack() {
  window.history.back();
}

if (!window.history) {
	$('.top__slider-back .page-name').html('Homepage');
}


if (window.history) {
	$(".top__slider-back").click(function(event) {
	    event.preventDefault();

	    window.history.back();
	});
}

$('.custom-logo-link').on('click', function(e){
	e.stopPropagation();
});


